// static query representing the searchable index of currently active listings

export function getListingSearchStaticQuery() {
  return null
}

export function getRecaptchaToken(actionName: any) {
  return new Promise(function(resolve, reject) {
    const grecaptcha = typeof window !== "undefined" && (window as any).grecaptcha;
    if (grecaptcha) {
      grecaptcha!.ready(function() {
        grecaptcha
          .execute(process.env.GOOGLE_RECAPTCHA_SITE_KEY, {
            action: actionName,
          })
          .then(function(token: any) {
            resolve(token)
          })
      })
    } else {
      reject("Recaptcha not added to site")
    }
  })
}

export function getSegmentAnalyticsSnipet() {
  const analitcsKey = process.env.GATSBY_SEGMENT_ANALYTICS_KEY
  if (
    typeof window == "undefined" ||
    !analitcsKey ||
    analitcsKey.length === 0
  ) {
    return null
  }

  const url = typeof window !== "undefined" && window.location.pathname

  return `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};;analytics.SNIPPET_VERSION="4.16.1";analytics.page(${
    url ? `"${url}"` : ""
  });}}();`
}

export function getSegmentAnalyticsInstance() {
  const analytics = typeof window !== "undefined" && (window as any).analytics
  return analytics
}

export function registerSegmentPageView() {
  let analytics = getSegmentAnalyticsInstance()
  analytics?.page()
}

export function registerSegmentActivity(event: any, properties: any) {
  let analytics = getSegmentAnalyticsInstance()
  analytics?.track(event, properties)
}

export function getSegmentAnonymousId() {
  let analytics = getSegmentAnalyticsInstance()
  return analytics?.user()?.anonymousId()
}

export function setOffMarketListingsSession() {
  var getCurrentTime = new Date().getTime()
  localStorage.setItem("offMarketSession", `${getCurrentTime + 432000000}`)
}

export function getOffMarketListingSession() {
  return localStorage.getItem("offMarketSession")
}

export function deleteOffMarketListingSession() {
  return localStorage.removeItem("offMarketSession")
}

export function isOffMarketListingSessionActive() {
  var getCurrentTime = new Date().getTime()
  var sessionExpiration = localStorage.getItem("offMarketSession")
  return getCurrentTime < Number(sessionExpiration)
}

export function filterListings(listings: any, agilityListingFilter: any) {
  const {
    exclusive,
    mLSIDs,
    majorAreas,
    minorAreas,
    cities,
    postalCodes,
    types,
    subTypes,
    subdivisions,
    neighborhoods,
    counties,
    statuses,
    officeMLSIDs,
    agentMLSIDs,
    features,
    openHouse,
    hasVirtualTour,
    flags,
  } = agilityListingFilter.customFields

  if (exclusive && exclusive == "true") {
    listings = listings.filter((value: any) => value.exclusive)
  }
  if (mLSIDs && mLSIDs.length > 0) {
    const mlsidArray = mLSIDs.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) => mlsidArray.includes(value.mlsID))
  }
  if (majorAreas && majorAreas.length > 0) {
    const majorAreasArray = majorAreas.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) =>
      majorAreasArray.includes(value.majorArea)
    )
  }
  if (minorAreas && minorAreas.length > 0) {
    const minorAreasArray = minorAreas.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) =>
      minorAreasArray.includes(value.minorArea)
    )
  }
  if (cities && cities.length > 0) {
    const citiesArray = cities.split(",").map((str: string) => str.trim())
    listings = listings.filter(
      (value: any) => value.address && citiesArray.includes(value.address.city)
    )
  }
  if (postalCodes && postalCodes.length > 0) {
    const postalCodesArray = postalCodes.split(",").map((str: string) => str.trim())
    listings = listings.filter(
      (value: any) => value.address && postalCodesArray.includes(value.address.postal)
    )
  }
  if (types && types.length > 0) {
    const typesArray = types.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) => typesArray.includes(value.type))
  }
  if (subTypes && subTypes.length > 0) {
    const subTypesArray = subTypes.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) => subTypesArray.includes(value.subType))
  }
  if (statuses && statuses.length > 0) {
    const statusesArray = statuses.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) => statusesArray.includes(value.status))
  }
  if (counties && counties.length > 0) {
    const countiesArray = counties.split(",").map((str: string) => str.trim())
    listings = listings.filter(
      (value: any) => value.address && countiesArray.includes(value.address.county)
    )
  }
  if (subdivisions && subdivisions.length > 0) {
    const subdivisionsArray = subdivisions.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) =>
      subdivisionsArray.includes(value.subdivision)
    )
  }
  if (neighborhoods && neighborhoods.length > 0) {
    const neighborhoodsArray = neighborhoods.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) =>
      neighborhoodsArray.includes(value.neighborhood)
    )
  }
  if (officeMLSIDs && officeMLSIDs.length > 0) {
    const officeMLSIDsArray = officeMLSIDs.split(",").map((str: string) => str.trim())
    listings = listings.filter(
      (value: any) =>
        value.office[0] && officeMLSIDsArray.includes(value.office[0].mlsID)
    )
  }

  if (agentMLSIDs && agentMLSIDs.length > 0) {
    const agentMLSIDsArray = agentMLSIDs.split(",").map((str: string) => str.trim())
    listings = listings.filter(
      (value: any) => value.agent[0] && agentMLSIDsArray.includes(value.agent[0].mlsID)
    )
  }
  if (features && features.length > 0) {
    const featuresArray = features.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) => featuresArray.includes(value.features))
  }
  if (openHouse && openHouse == "true") {
    listings = listings.filter((value: any) => value.hasOpenHouse)
  }
  if (hasVirtualTour && hasVirtualTour == "true") {
    listings = listings.filter((value: any) => value.hasVirtualTour)
  }
  if (flags && flags.length > 0) {
    const flagsArray = flags.split(",").map((str: string) => str.trim())
    listings = listings.filter((value: any) => flagsArray.includes(value.flags))
  }
}
