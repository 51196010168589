import { ISearchProps } from "@organic-return/foundation-react/src/components/Search/Search"
import { IEntrySearchProps } from "@organic-return/foundation-react/src/components/Search/EntrySearch"
import { useContext, createContext, ReactNode } from "react"
import { IContactFormProps } from "@organic-return/foundation-react/src/components/Forms/ContactForm"
import { IFooterProps } from "@organic-return/foundation-react/src/components/Footer/util"
import { IListingLayoutProps } from "@organic-return/foundation-react/src/components/Listing/util"
import { ISellFormProps } from "@organic-return/foundation-react/src/components/Forms/SellForm"
import { IHeaderProps } from "@organic-return/foundation-react/src/components/Header/Header"
import { IAgentLayoutProps } from "@organic-return/foundation-react/src/components/Contact/util"
import { ICarouselProps } from "@organic-return/foundation-react/src/components/Carousel"
import { IListingCardProps } from "@organic-return/foundation-react/src/components/Listing/ListingCard"
import { ICommunityLayoutProps } from "@organic-return/foundation-react/src/components/Community/util"
import { IPageLayoutTabsProps } from "@organic-return/foundation-react/src/components/PageLayouts/PageLayoutTabs"
import { ILocationSearchProps } from "@organic-return/foundation-react/src/components/Forms/LocationSearch"
import { IContactCardProps } from "@organic-return/foundation-react/src/components/Contact"
import { ISectionWithCarouselColumnProps } from "@organic-return/foundation-react/src/components/Blades/SectionWithCarouselColumn"
import { IAgentMapSearchProps } from "@organic-return/foundation-react/src/components/Blades/AgentMapSearch"
import { ITwoColumnAgentBladeProps } from "@organic-return/foundation-react/src/components/Blades/TwoColumnAgentBlade"
import { ISearchCompactProps } from "@organic-return/foundation-react/src/components/Search/SearchCompact"
import { IBlogPostLayoutProps } from "@organic-return/foundation-react/src/components/BlogPost/utils"
import { IOfficeLayoutProps } from "@organic-return/foundation-react/src/components/Office/utils"

export interface ISiteContext {
  /**
   * Additional props for the global listing search component
   */
  listingSearchProps?: Partial<ISearchProps>
  /**
   * Additional props for the community page embedded listing search
   */
  communitySearchProps?: Partial<ISearchProps>
  /**
   * Additional props for the global rental search component
   */
  rentalSearchProps?: Partial<ISearchProps>
  /**
   * Additional props for the global listing entry search component
   */
  listingEntrySearchProps?: Partial<IEntrySearchProps>
  /**
   * Additional props for the global rental entry search component
   */
  rentalEntrySearchProps?: Partial<IEntrySearchProps>
  /**
   * Additional props for the global rental entry search component
   */
  rentalEntryAdvancedSearchProps?: Partial<IEntrySearchProps>
  /**
   * Additional props the search component when searching contacts/people
   */
  peopleSearchProps?: Partial<ISearchProps>
  /**
   * Function to provide local overrides of components for Agility
   * Page Modules
   */
  localGetModule?: (name: string) => any
  /**
   * Function to provide local overrides of components for Agility
   * Page Templates
   */
  localGetPageTemplate?: (name: string) => any
  /**
   * Additional properties to pass to the global contact form
   */
  globalContactFormProps?: Partial<IContactFormProps>
  /**
   * Additional properties to pass to the global sell form
   */
  globalSellFormProps?: Partial<ISellFormProps>
  /**
   * Additional properties to pass to the global footer
   */
  footerProps?: Partial<IFooterProps>
  /**
   * Additional properties to pass to the default RE listing layout
   */
  listingLayoutProps?: Partial<IListingLayoutProps>
  listingLayout?: React.FC<IListingLayoutProps>
  /**
   * Additional properties to pass to the exclusive RE listing layout
   */
  exclusiveLayoutProps?: Partial<IListingLayoutProps>
  exclusiveListingLayout?: React.FC<IListingLayoutProps>
  /**
   * Additional properties to pass to the exclusive rental listing layout
   */
  rentalLayoutProps?: Partial<IListingLayoutProps>
  /**
   * Additional properties to pass to the global header
   */
  headerProps?: Partial<IHeaderProps>
  /**
   * Additional props the search component when searching partners
   */
  partnersListingProps?: Partial<ISearchProps>
  /**
   * Additional props to pass to the agent layout
   */
  agentLayoutProps?: Partial<IAgentLayoutProps>
  /**
   * Desired agent page layout
   */
  agentPageLayout?: React.FC<IAgentLayoutProps>
  /**
   * Additional props to pass to listing carousel
   */
  globalListingCarouselProps?: Partial<ICarouselProps>
  /**
   * Additional props to pass to listing carousel card
   */
  globalListingCarouselCardProps?: Partial<IListingCardProps>
  /**
   * Additional props to pass to listing  card
   */
  globalListingCardProps?: Partial<IListingCardProps>
  /**
   * Desired community page layout
   */
  communityPageLayout?: React.FC<ICommunityLayoutProps>
  /**
   * Additional properties to pass to the community layout
   */
  communityLayoutProps?: Partial<ICommunityLayoutProps>
  globalCommunitiesContentWithTabsProps?: Partial<IPageLayoutTabsProps>
  /**
   * Desired footer layout
   */
  footerLayout?: React.FC<IFooterProps>
  /**
   * Additional props to pass to location search
   */
  locationSearchProps?: Partial<ILocationSearchProps>
  globalPeopleSearchCardProps?: Partial<IContactCardProps>
  /**
   * Additional props the search component when searching builders
   */
  buildersSearchProps?: Partial<ISearchProps>
  peopleEntrySearchProps?: Partial<IEntrySearchProps>
  globalOfficesCarouselProps?: Partial<ISectionWithCarouselColumnProps>
  /**
   * Additional props for the global listing search component
   */
  listingSearchCompactProps?: Partial<ISearchCompactProps>
  globalPartnerMapSearchProps?: Partial<IAgentMapSearchProps>
  globalFeaturedAffiliatedAgentProps?: Partial<ITwoColumnAgentBladeProps>
  /**
   * Additional properties to pass to the blog post layout
   */
  blogPostPageLayoutProps?: Partial<IBlogPostLayoutProps>
  /**
   * Additional properties to pass to the blog post layout
   */
  officePageLayoutProps?: Partial<IOfficeLayoutProps>
  /**
   * Additional properties to pass to the default RE listing print layout
   */
  listingPrintLayoutProps?: Partial<IListingLayoutProps>
  exclusiveListingPrintLayoutProps?: Partial<IListingLayoutProps>
}

export const SiteContext = createContext<ISiteContext>({})

export interface ISiteContextProviderProps {
  children?: ReactNode
}

export const useSiteContext = () => useContext(SiteContext)
