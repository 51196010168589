import { SiteContext } from "../components/common/SiteContext"
import { IListing, IListingSummary } from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React, { useEffect, useState } from "react"
import {
  Check,
  Choose,
  Text,
  InputSlider,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { getPageTemplate } from "../components/agility-pageTemplates"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { EListingDetailsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingDetails"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { ISearchContext, useSearch } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { IAgent } from "@organic-return/foundation-react/src/lib"
import { Button } from "@organic-return/foundation-react/src/components/Button"
import { ListingLayoutSingleColumn } from "@organic-return/foundation-react/src/components/Listing"
import { ELisitngFormLocation, EMediaSectionLayout } from "@organic-return/foundation-react/src/components/Listing/util"
import { AgilityImage } from "@agility/gatsby-image-agilitycms"
import { ECardIconsLayout } from "@organic-return/foundation-react/src/components/Contact"
import { EContactFormLayout } from "@organic-return/foundation-react/src/components/Forms"
import { FooterLayoutFormColumn } from "@organic-return/foundation-react/src/components/Footer/FooterLayoutFormColumn"
import { CommunityLayoutHeroGallery } from "@organic-return/foundation-react/src/components/Community/CommunityLayoutHeroGallery"
import { ECarouselLinkPosition } from "@organic-return/foundation-react/src/components/Carousel"
import { getRecaptchaToken } from "../lib/utils"
import axios from "axios"

export const SiteContextProvider: React.FC = ({ children }: any) => {
  const data = useStaticQuery(graphql`
    query SiteContextProviderQuery {
      config: agilitySiteConfiguration {
        customFields {
          siteName
          rentalSearchPath
          listingSearchPath
          logo {
            url
            height
            width
            label
          }
          copyright
        }
      }
      allZipCode(sort:{fields:zip}) {
        nodes {
          zip
        }
      }
      allCounty {
        nodes {
          name
          city
        }
      }
    }
  `)
  const selectStyles = {
    control: (base: object) => ({
      ...base,
      borderColor: "transparent",
      background: "transparent",
      borderRadius: 0,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
      with: "100%"
    }),
    input: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-body-text)",
      textTransform: "uppercase",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-button-bg)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      textTransform: "uppercase",
    }),
    menu: (base: object) => ({
      ...base,
      width: "min-content"
    }),

  }

  const priceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "> $200k", value: "200000" },
            { label: "> $400k", value: "400000" },
            { label: "> $600k", value: "600000" },
            { label: "> $800k", value: "800000" },
            { label: "> $1M", value: "1000000" },
            { label: "> $3M", value: "3000000" },
            { label: "> $5M", value: "5000000" },
            { label: "> $7M", value: "7000000" },
            { label: "> $10M", value: "10000000" },
            { label: "> $15M", value: "15000000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $200k", value: "200000" },
            { label: "< $400k", value: "400000" },
            { label: "< $600k", value: "600000" },
            { label: "< $800k", value: "800000" },
            { label: "< $1M", value: "1000000" },
            { label: "< $3M", value: "3000000" },
            { label: "< $5M", value: "5000000" },
            { label: "< $7M", value: "7000000" },
            { label: "< $10M", value: "10000000" },
            { label: "< $15M", value: "15000000" },
          ]}
        />
      </>
    )
  }

  const bedroomsFilter = function (context: ISearchContext, className: string = "") {

    return (
      <>
        <Choose
          className={`col-span-6 md:col-span-2 lg:col-span-1 flex justify-center items-center h-20 md:h-24 border-inherit border-t lg:border-t-0 ${className}`}
          context={context}
          label="Beds"
          placeholder="Beds"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.beds || 0) >= parseInt(vstr) || false
          }}
          param="beds"
          resolver={arg => arg.beds}
          reactSelectStyles={selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  const bathroomsFilter = function (context: ISearchContext) {
    return (
      <>
        <Choose
          className="col-span-3 lg:col-span-1 flex justify-center items-center h-20 md:h-24  border-r border-inherit"
          context={context}
          label="Baths"
          placeholder="Baths"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.baths || 0) >= parseInt(vstr) || false
          }}
          param="baths"
          resolver={arg => arg.baths}
          reactSelectStyles={selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  const countyOptions = [{ label: "COUNTY", value: "" }]
  const countiesAndCitiesOptions = { "All": [{ label: "CITY", value: "" }], "": [{ label: "CITY", value: "" }] }
  data.allCounty?.nodes?.forEach((county: any) => {
    countyOptions.push({ label: county.name, value: county.name })

    let cities = county.city?.sort().map((c: any) => { return { label: c, value: c } })
    cities = [{ label: "CITY", value: "" }, ...cities]
    countiesAndCitiesOptions[county.name as keyof typeof countiesAndCitiesOptions] = cities
  })
  const [cityOptions, setCityOptions] = useState<any>([{ label: "CITY", value: "" }])
  const [countyValue, setCountyValue] = useState<string>("")
  useEffect(() => {
    setCityOptions(countiesAndCitiesOptions[countyValue as keyof typeof countiesAndCitiesOptions])
  }, [countyValue])
  /**
   * Standard filters for global Real Estate listing search.
   */
  const [isExpandedSearch, setExpandedSearch] = useState<boolean>(false)
  const expandedSearchHandler = () => {
    setExpandedSearch(!isExpandedSearch)
  }
  const listingStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <div className="sm:col-span-2 lg:col-span-4 grid grid-cols-6 border-t border-b border-body-text">
          <Choose
            className="col-span-3 md:col-span-2 lg:col-span-1 flex justify-center items-center h-20 md:h-24 border-r border-inherit"
            context={context}
            placeholder="County"
            label="County"
            param="county"
            resolver={arg => arg.address.county}
            match="one"
            reactSelectStyles={selectStyles}
            showAllEntriesOption={true}
            allEntriesOptionText="COUNTY"
            onChange={(option: any) => {
              setCountyValue(option.value)
              context.setFilterValue!("city", null)
            }}
            onSetInitialValue={(value: any) => {
              setCountyValue(value)
            }}
          />
          <Choose
            className="col-span-3 md:col-span-2 lg:col-span-1 flex justify-center items-center h-20 md:h-24 md:border-r border-inherit"
            context={context}
            placeholder="City"
            label="City"
            param="city"
            resolver={arg => arg.address.city}
            match="one"
            reactSelectStyles={selectStyles}
            options={cityOptions}
          />
          <Choose
            className="col-span-6 md:col-span-2 lg:col-span-1 flex justify-center items-center h-20 md:h-24 border-t md:border-t-0 lg:border-r border-inherit"
            context={context}
            placeholder="Zip Code"
            label="Zip Code"
            param="postal"
            resolver={arg => arg.address.postal}
            match="one"
            reactSelectStyles={selectStyles}
            showAllEntriesOption={true}
            allEntriesOptionText="ZIP CODE"
          />
          <div className="col-span-6 md:col-span-4 lg:col-span-2 flex flex-row items-center h-20 md:h-24 border-t lg:border-t-0 md:border-r border-inherit px-10 md:px-14">
            <span>PRICE</span>
            <InputSlider
              containerClassName="lg:col-span-2"
              context={context}
              min={0}
              max={20000000}
              step={100000}
              indexFieldMin={"price"}
              indexFieldMax={"price"}
              paramMax="maxPrice"
              paramMin="minPrice"
              valueOutputFormat={value =>
                "$" + new Intl.NumberFormat().format(value)
              }
            />
          </div>
          {bedroomsFilter(context)}
        </div>
        <div className="sm:col-span-2 lg:col-span-4 flex flex-row flex-wrap md:flex-nowrap justify-center items-center">
          <Text
            context={context}
            placeholder="MLS#, City, Zip, Area"
            param="text"
            label="MLS#, City, Zip, Area"
            inputClassName="bg-transparent text-body-text border-body-text placeholder:text-inherit"
            resolver={(arg: any, text) => {
              let argl = arg as IListingSummary
              let match = text?.toLowerCase()
              return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
                (argl.address.text &&
                  argl.address.text.toLowerCase().includes(match)) ||
                (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
                (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
                (argl.subdivision &&
                  argl.subdivision.toLowerCase().includes(match)) ||
                (argl.neighborhood &&
                  argl.neighborhood.toLowerCase().includes(match))
                ? true
                : false
            }}
          />
          <span className="text-body-text not-italic text-3xl m-9">{"OR"}</span>
          <Check
            context={context}
            label="Our Properties Only"
            inputClassName="bg-body-bg"
            param="ours"
            className="text-body-text pr-4 border-r border-text-composite"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.exclusive || false
            }}
          />
          <button className="text-body-text ml-4" type="button" onClick={expandedSearchHandler}>
            {`${isExpandedSearch ? "Hide " : ""}Power Search <<`}
          </button>
        </div>
        {isExpandedSearch && (
          <>
            <div className="sm:col-span-2 lg:col-span-4 grid grid-cols-6 border-t border-b border-body-text">
              {bathroomsFilter(context)}
              <Choose
                className="col-span-3 lg:col-span-1 flex justify-center items-center h-20 md:h-24 lg:border-r border-inherit"
                context={context}
                placeholder="Type"
                label="Type"
                param="subType"
                resolver={arg => arg.subType}
                sort="count"
                match="one"
                allEntriesOptionText="All"
                showAllEntriesOption={true}
                reactSelectStyles={selectStyles}
              />
              <div className="col-span-6 md:col-span-4 lg:col-span-2 flex flex-row items-center h-20 md:h-24 border-t lg:border-t-0 md:border-r border-inherit px-10 md:px-14">
                <span>SQUARE FEET</span>
                <InputSlider
                  containerClassName="lg:col-span-2"
                  context={context}
                  min={0}
                  max={5000}
                  step={100}
                  indexFieldMin={"sqft"}
                  indexFieldMax={"sqft"}
                  paramMax="maxSqft"
                  paramMin="minSqft"
                  valueOutputFormat={value =>
                    new Intl.NumberFormat().format(value)
                  }
                />
              </div>
              <Choose
                className="col-span-6 md:col-span-2 lg:col-span-1 flex justify-center items-center h-20 md:h-24 lg:border-r border-t lg:border-t-0 border-inherit"
                context={context}
                label="Rooms"
                placeholder="Rooms"
                customFilter={(arg, value) => {
                  let argl = arg as IListingSummary
                  let vstr = value as string
                  if (vstr === "All") {
                    return true;
                  }
                  else {
                    return (argl.rooms || 0) >= parseInt(vstr) || false
                  }
                }}
                param="rooms"
                resolver={arg => arg.rooms}
                reactSelectStyles={selectStyles}
                options={[
                  { label: "All", value: "All" },
                  { label: "1+", value: "1" },
                  { label: "2+", value: "2" },
                  { label: "3+", value: "3" },
                  { label: "4+", value: "4" },
                  { label: "5+", value: "5" },
                  { label: "6+", value: "6" },
                  { label: "7+", value: "7" },
                  { label: "8+", value: "8" },
                  { label: "9+", value: "9" },
                  { label: "10+", value: "10" },
                  { label: "11+", value: "11" },
                ]}
              />
              <Choose
                className="col-span-6 lg:col-span-1 flex justify-center items-center h-20 md:h-24 border-t lg:border-t-0 border-inherit"
                context={context}
                label="Parking Features"
                placeholder="Parking Features"
                param="parking"
                resolver={arg => arg.parkingFeatures}
                reactSelectStyles={selectStyles}
                showAllEntriesOption={true}
                allEntriesOptionText="All"
              />
            </div>
            <div className="sm:col-span-2 lg:col-span-4 grid grid-cols-3 gap-y-2 px-2">
              <Check
                context={context}
                label="New Construction"
                inputClassName="bg-body-bg"
                param="newlyBuilt"
                className="text-body-text col-span-3 md:col-span-1 md:justify-self-center"
                resolver={(arg: any) => {
                  let argl = arg as IListing
                  return argl.newlyBuilt || false
                }}
              />
              <Check
                context={context}
                label="Exclude Under Contract"
                inputClassName="bg-body-bg"
                param="activeOnly"
                className="text-body-text col-span-3 md:col-span-1 md:justify-self-center"
                resolver={(arg: any) => {
                  let argl = arg as IListingSummary
                  return argl.status === "Active"
                }}
              />
              <Check
                context={context}
                label="Has Virtual Tour"
                inputClassName="bg-body-bg"
                param="virtualTour"
                className="text-body-text col-span-3 md:col-span-1 md:justify-self-center"
                resolver={(arg: any) => {
                  let argl = arg as IListingSummary
                  return argl.hasVirtualTour || false
                }}
              />
            </div>
          </>
        )}
      </>
    )
  }

  const zipCodeOptions = [{ label: "ZIP CODE", value: "" }]
  data.allZipCode?.nodes?.forEach((code: any) => {
    zipCodeOptions.push({ label: code.zip, value: code.zip })
  })

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = function (context: ISearchContext) {
    return (
      <div className="col-span-2 md:col-span-4 grid grid-cols-6 border-t border-b border-button-bg">
        <Choose
          className="col-span-3 md:col-span-2 lg:col-span-1 flex justify-center items-center h-20 md:h-24 border-r border-inherit"
          context={context}
          placeholder="County"
          label="County"
          param="county"
          resolver={arg => arg.address.county}
          match="one"
          reactSelectStyles={selectStyles}
          options={countyOptions}
          onChange={(option: any) => {
            setCountyValue(option.value)
            context.setFilterValue!("city", null)
          }}
          onSetInitialValue={(value: any) => {
            setCountyValue(value)
          }}
        />
        <Choose
          className="col-span-3 md:col-span-2 lg:col-span-1 flex justify-center items-center h-20 md:h-24 md:border-r border-inherit"
          context={context}
          placeholder="City"
          label="City"
          param="city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={cityOptions}
        />
        <Choose
          className="col-span-6 md:col-span-2 lg:col-span-1 flex justify-center items-center h-20 md:h-24 border-t md:border-t-0 md:border-r border-inherit"
          context={context}
          placeholder="Zip Code"
          label="Zip Code"
          param="postal"
          resolver={arg => arg.address.postal}
          match="one"
          reactSelectStyles={selectStyles}
          options={zipCodeOptions}
        />
        <div className="col-span-6 md:col-span-4 lg:col-span-2 flex flex-row items-center h-20 md:h-24 border-t lg:border-t-0 md:border-r border-inherit px-10 md:px-14">
          <span>PRICE</span>
          <InputSlider
            context={context}
            min={0}
            max={20000000}
            step={100000}
            indexFieldMin={"price"}
            indexFieldMax={"price"}
            paramMax="maxPrice"
            paramMin="minPrice"
            valueOutputFormat={value =>
              "$" + new Intl.NumberFormat().format(value)
            }
          />
        </div>
        {bedroomsFilter(context, "md:border-r")}
      </div>
    )
  }
  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          reactSelectStyles={selectStyles}
          match="any"
        />
        {priceFilters(context)}
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
      </>
    )
  }
  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.price || 0) - (l0.price || 0)
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.price || 0) - (l1.price || 0)
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sq Ft: High to Low",
            key: "sqftDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sqft || 0) - (l0.sqft || 0)
            },
          },
          {
            label: "Sq Ft: Low to High",
            key: "sqftASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sqft || 0) - (l1.sqft || 0)
            },
          },
          {
            label: "Lot Size: High to Low",
            key: "lotDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.lotacres || 0) - (l0.lotacres || 0)
            },
          },
          {
            label: "Lot Size: Low to High",
            key: "lotASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.lotacres || 0) - (l1.lotacres || 0)
            },
          },
          {
            label: "Last Updated",
            key: "statusTSDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.statusTS || "").localeCompare(l0.statusTS || "")
            },
          },
        ]}
      />
    )
  }

  const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  const letterFilter = function (context: ISearchContext) {
    const letterOptions = alphabet.map((item) => {
      return {
        label: item,
        value: item
      }
    })
    letterOptions.push({
      label: "All",
      value: ""
    })

    return (
      <Choose
        className="hidden"
        context={context}
        label="Letter"
        placeholder="Letter"
        customFilter={(arg, value) => {
          let argl = arg as IAgent
          let vstr = value as string
          vstr = vstr.toLowerCase()
          return argl.first &&
            argl.first.toLowerCase().startsWith(vstr) ? true : false
        }}
        param="letter"
        resolver={arg => arg.price}
        reactSelectStyles={selectStyles}
        options={letterOptions}
      />
    )
  }

  const categoryFilterHandler = (context: ISearchContext, value: string) => {
    context && context.setFilterValue!("category", value)
  }
  const letterFilterHandler = (context: ISearchContext, value: string) => {
    if (!value || value.trim().length === 0) {
      context && context.setFilterValue!("letter", null)
    }
    else {
      context && context.setFilterValue!("letter", value)
    }
  }

  const peopleStandarSearch = (context: ISearchContext) => {
    const currentFilterValues = context.currentFilterValues as any
    return (
      <>
        <div className="justify-self-center sm:col-span-2 lg:col-span-4 w-full max-w-screen-lg flex flex-col items-center">
          <ul className="flex flex-row flex-wrap md:flex-nowrap justify-center md: justify-start pt-1 pb-4">
            <li className={`px-2 mx-0.5 uppercase ${(!currentFilterValues?.category || currentFilterValues?.category == "Advisor") ? "border-b border-button-bg" : ""}`}>
              <a onClick={() => categoryFilterHandler(context, "Advisor")} className="text-body-text cursor-pointer">
                Advisors
              </a>
            </li>
            <li className={`px-2 mx-0.5 uppercase ${currentFilterValues?.category == "Leadership" ? "border-b border-button-bg" : ""}`}>
              <a onClick={() => categoryFilterHandler(context, "Leadership")} className="text-body-text cursor-pointer">
                Management
              </a>
            </li>
            <li className={`px-2 mx-0.5 uppercase ${currentFilterValues?.category == "Staff" ? "border-b border-button-bg" : ""}`}>
              <a onClick={() => categoryFilterHandler(context, "Staff")} className="text-body-text cursor-pointer">
                Staff
              </a>
            </li>
          </ul>
          <ul className="bg-body-bg-inverted flex flex-row flex-wrap lg:flex-nowrap justify-center lg:justify-start gap-y-2 p-4">
            <li className={`p-2 ${!currentFilterValues?.letter ? "border border-white" : ""}`}>
              <a onClick={() => letterFilterHandler(context, "")} className="text-body-text cursor-pointer">
                All
              </a>
            </li>
            {alphabet.map((item, index) => (
              <li key={index} className={`p-2 ${currentFilterValues?.letter == item ? "border border-white" : ""}`}>
                <a onClick={() => letterFilterHandler(context, item)} key={index} className="text-body-text cursor-pointer">
                  {item}
                </a>
              </li>
            ))}
          </ul>
          {letterFilter(context)}
          <Choose
            className="hidden"
            context={context}
            label="Category"
            placeholder="Category"
            param="category"
            match="any"
            resolver={arg => arg.category}
          />
        </div>
      </>
    )
  }

  const peopleSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "First Name",
            key: "firstName",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IAgent
              let l1 = arg1 as IAgent

              let firstName0 = l0.first!.toLowerCase().trim();
              let firstName1 = l1.first!.toLowerCase().trim();

              if (firstName0 < firstName1) {
                return -1;
              }
              if (firstName0! > firstName1) {
                return 1;
              }
              return 0;
            },
          },
          {
            label: "Last Name",
            key: "lastName",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IAgent
              let l1 = arg1 as IAgent

              let lastName0 = l0.last!.toLowerCase().trim();
              let lastName1 = l1.last!.toLowerCase().trim();

              if (lastName0 < lastName1) {
                return -1;
              }
              if (lastName0 > lastName1) {
                return 1;
              }
              return 0;
            },
          },
        ]}
      />
    )
  }

  const PrevArrow = (props: any) => {
    return (
      <button
        {...props}
        className="image-gallery-icon image-gallery-left-nav text-7xl"
      >
      </button>
    );
  }

  const NextArrow = (props: any) => {
    return (
      <button
        {...props}
        className="image-gallery-icon image-gallery-right-nav text-7xl "
      >

      </button>
    );
  }

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{
        localGetPageTemplate: getPageTemplate,
        listingLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "mb-3 text-center uppercase z-50",
            layout: EListingVitalsLayout.column2Border2,
            locationIcon: false,
          },
          summaryProps: {
            showFeatures: false,
            callToAction: { href: "#requestInfoForm", text: "Inquire About This Property" },
            className: "[&>.listing-features]:text-left [&>.listing-features]:mx-4"
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn bg-body-bg-inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
            fieldsetClassName: "mx-auto w-full max-w-screen-2xl flex flex-row flex-wrap justify-center md:justify-start items-center pt-5 lg:pt-10",
            mainLegendClassName: "relative float-left w-full md:text-left md:w-[51%] md:border-r md:border-body-text md:px-8 order-1 !pt-0",
            mainListClassName: "order-2 !mx-0 md:!mx-0 md:w-[51%] md:border-r md:border-body-text md:px-8 !my-0 md:!my-0 py-8",
            submitButtonClassName: "bg-body-bg w-full !max-w-none font-black !uppercase",
            inputTextAreaClassName: "!h-[120px]",
            formId: "requestInfoForm",
          },
          optionalFormChildrenProp: (listing: IListing) => {
            return (
              <div className="md:pl-10 lg:pl-44 order-3 flex flex-col items-center md:items-start md:w-[49%]">
                <AgilityImage
                  image={{
                    url: data.config.customFields.logo?.url,
                    height: data.config.customFields.logo?.height,
                    width: data.config.customFields.logo?.width,
                    label: "logo"
                  }}
                  className="pb-5"
                  imgClassName="w-full md:!w-[83%]"
                  imgStyle={{
                    filter: "invert(1)",
                    objectFit: "scale-down"
                  }}
                />
                <address className="flex flex-col text-center md:text-left !not-italic">
                  423 Mandalay Ave, Suite 102 <br />
                  Clearwater Beach, FL 33767 <br />
                  P: +1 727-493-1555 <br />
                  info@coastalpgi.com
                </address>
                <div className="text-center md:text-left text-xs pt-6">
                  Listing Courtesy of {listing.office?.[0].fullName}
                  {listing.office?.[0].phone && (
                    <>
                      &nbsp;
                      <a className="text-body-text" href={`tel:${listing.office?.[0].phone}`}>
                        {listing.office?.[0].phone}
                      </a>
                    </>
                  )}
                </div>
              </div>
            )
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: {
            title: "Location",
            className: "w-full mb-10",
            googleMapsProps: {
              mapContainerStyle: {
                height: "74vh",
                width: "83vw",
                margin: "auto"
              }
            },
            titleClassName: "text-center text-[64px] mb-10",
          },
          detailsProps: {
            title: "Details",
            titleClassName: "py-11 mb-11 text-[64px]",
            layout: EListingDetailsLayout.groupColumns,
            className: "w-full bg-body-bg-inverted flex flex-col items-center px-10 pb-15 my-10",
          },
          carouselProps: {
            slickSettings: {
              nextArrow: <NextArrow />,
              prevArrow: <PrevArrow />
            },
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto single-col-summary",
          showContactUsForm: false,
          listingFormLocation: ELisitngFormLocation.pageSection,
          heroWrapperClassName: "!mb-0",
          mediaSectionLayout: EMediaSectionLayout.separated,
          mediaSectionTitle: "Gallery",
          photosTabTitle: "",
          galleryProps: {
            className: "w-full listing-img-gallery"
          },
          formColumnClassName: "hidden",
        },
        listingLayout: ListingLayoutSingleColumn,
        exclusiveLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "mb-3 text-center uppercase z-50",
            layout: EListingVitalsLayout.column2Border2,
            locationIcon: false,
          },
          summaryProps: {
            showFeatures: false,
          },
          formProps: {
            title: "INQUIRE ABOUT PROPERTY",
            classNames: "strictTwoColumn inverted property-contact",
            fieldsetClassName: "!py-1.5 !px-6 lg:!py-6 lg:!px-14 lg:border-l lg:!border-body-text flex flex-col",
            mainListClassName: "!my-0",
            submitButtonClassName: "font-black !uppercase !text-sm !py-2",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: {
            title: "Location",
            className: "w-full mb-10",
            googleMapsProps: {
              mapContainerStyle: {
                height: "74vh",
                width: "83vw",
                margin: "auto"
              }
            },
            titleClassName: "text-center text-[64px] mb-10",
          },
          detailsProps: {
            title: "Details",
            titleClassName: "py-11 mb-11 text-[64px]",
            layout: EListingDetailsLayout.groupColumns,
            className: "w-full bg-body-bg-inverted flex flex-col items-center px-10 pb-15 my-10",
          },
          carouselProps: {
            slickSettings: {
              nextArrow: <NextArrow />,
              prevArrow: <PrevArrow />
            },
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "summary-form-section md:!space-x-0 w-full bg-body-bg-inverted !flex md:!flex flex-row flex-wrap items-center py-16 [&>div:nth-child(1)]:before:content-['About_This_Property'] [&>div:nth-child(1)]:before:text-[64px] [&>div:nth-child(1)]:before:font-extralight [&>div:nth-child(1)]:before:font-h1 [&>div:nth-child(1)]:before:block [&>div:nth-child(1)]:before:text-center [&_.listing-features]:text-left [&_.listing-features]:mx-4",
          formColumnClassName: "",
          showContactUsForm: false,
          contactProps: {
            offices: [],
            contactCardProps: {
              className: "!w-full",
              imageProps: {
                style: {
                  height: "200px"
                }
              },
              showSocial: false,
            }
          },
          heroWrapperClassName: "!mb-0",
          mediaSectionLayout: EMediaSectionLayout.separated,
          mediaSectionTitle: "Gallery",
          photosTabTitle: "",
          galleryProps: {
            className: "w-full listing-img-gallery"
          }
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          sort: listingSort,
          filterClassName: "bg-body-bg-inverted !px-0 !pt-36",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-99",
          resultsClassName: "max-w-screen-2xl mx-auto gap-y-9",
        },
        listingEntrySearchProps: {
          standardFilters: listingEntryFilters,
          className: "!p-0",
          buttonClassName: "!m-0 !flex !items-center !justify-center !text-lg !font-bold uppercase !w-full h-full md:h-1/2 lg:h-2/3 hover:!text-button-text",
          onSubmit: async (filters) => {
            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            setExpandedSearch(false)
            await navigate(
              data.config.customFields.listingSearchPath + "#" + params.toString() + "&sort=\"priceDESC\""
            )
          },
          buttonText: "GO",
          showAdvancedComponent: () => {
            const searchContext = useSearch()
            return (
              <>
                <div className="hidden md:block bg-black rounded-full font-extralight absolute text-center text-composite-text p-1 text-[9px] lg:bottom-6">OR</div>
                <Button
                  linkProps={{
                    className: "!m-0 hidden md:flex items-center justify-center text-xs font-bold uppercase w-full h-1/2 lg:h-1/3 !bg-[#a33038] hover:!text-button-text border-t border-text-composite",
                    onClick: async () => {
                      const params = new URLSearchParams({
                        filters: JSON.stringify(searchContext.currentFilterValues || {}),
                      })
                      setExpandedSearch(true)
                      await navigate(
                        data.config.customFields.listingSearchPath + "#" + params.toString() + "&sort=\"priceDESC\""
                      )
                    }
                  }}
                >
                  Power Search
                </Button>
              </>
            )
          }
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          mapClassName: "w-99",
          sort: listingSort,
        },
        peopleSearchProps: {
          className: "people-search",
          headerClassName: "max-w-screen-lg mx-auto justify-center sm:!justify-center gap-x-8",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto !grid !grid-cols-1 lg:!grid-cols-2 xl:!grid-cols-3 !justify-center !items-center !px-1 !py-7",
          filterClassName: "!py-0",
          primaryFilters: peopleStandarSearch,
          sort: peopleSort,
          defaultSort: "firstName",
          initialFilter: '{"category": "Advisor"}'
        },
        globalPeopleSearchCardProps: {
          className: "!bg-body-bg-inverted !gap-0 m-2 [&>div>div:nth-child(2)]:grow [&>div>hr:nth-child(2)]:grow [&>div>hr:nth-child(2)]:border-0",
          showVCard: true,
          showEmail: false,
          showSocial: false,
          imageProps: {
            wrapperProps: {
              style: {
                display: "block",
                height: "100%",
                width: "100%",
              }
            },
            style: {
              height: "100%",
              width: "100%",
            }
          }
        },
        globalListingCarouselProps: {
          slickSettings: {
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
          }
        },
        globalListingCarouselCardProps: {
          vitalsTwoLineAddress: false,
          vitalsLocationIcon: true
        },
        globalListingCardProps: {
          vitalsTwoLineAddress: false,
          //vitalsLocationIcon: false,
          vitalsLayout: EListingVitalsLayout.column1Border0,
          vitalsProps: {
            priceClassName: "!text-[3.1rem] font-[HelveticaNeue-CondensedBold] text-[#003a59]",
            vitalIconsClassName: "border-l-[3px] !border-[#003a59] h-full flex items-center",
            locationClassName: "mt-4",
            locationIcon: true
          }
        },
        agentLayoutProps: {
          className: "!mt-0",
          showCarousel: false,
          contactCardProps: {
            showEmail: false,
            showVCard: true,
            showWebsite: true,
            showSocial: true,
            contactInfoItemsLayout: ECardIconsLayout.columns1row,
            className: "!gap-8 pt-40 pb-28 !px-4 lg:!px-3 xl:!pl-32 xl:!pr-20 md:!grid-cols-1 lg:!grid-cols-3",
            agentInfoClassName: "md:!col-span-1 lg:!col-span-2 items-center lg:items-start",
            imageProps: {
              wrapperProps: {
                className: "!flex !flex-col !justify-center !items-center lg:!items-end",
              },
              className: "h-60 lg:h-72 xl:h-80",
              style: {}
            }
          },
          showAgentBioReadMoreButton: false,
          testimonialsProps: {
            showAttribution: true
          },
          requestInfoFormProps: {
            classNames: "bg-body-text",
            layout: EContactFormLayout.column3,
            title: "Contact",
            inputTextAreaLabel: "Add message here...",
            inputTextClassNames: "!border-composite-text !text-composite-text [&::placeholder]:!text-composite-text",
            mainLegendClassName: "text-center text-composite-text text-3xl xl:text-5xl !normal-case",
            mainListClassName: "!max-w-screen-xl !mx-auto",
            submitButtonClassName: "!bg-[#9bafb3] !text-button-text !mt-11 !uppercase font-black text-xs"
          },
          listingsTabsProps: {
            className: "pb-10",
            titleClassName: "text-3xl xl:text-5xl font-bold pt-10",
            activeListingsTabTitle: "Active",
            soldListingsTabTitle: "Sold",
            defaultTabClass: "uppercase text-[#9bafb3] text-lg font-bold !w-auto px-1 mx-4",
            activeTabClass: "uppercase text-body-text text-lg border-b-4 border-[#9bafb3] font-bold !w-auto px-1 mx-4",
          },
          tabsListingCardVitalsLayout: {
            vitalsLayout: EListingVitalsLayout.column2Column1Border1,
            vitalsLocationIcon: true
          }
        },
        footerProps: {
          logoClassName: "!w-[128px]",
          secondaryLogoClassName: "!w-[125px]",
          copyright: data.config.customFields.copyright,
          formProps: {
            classNames: "strictTwoColumn allFields",
            fieldsetClassName: "!p-0",
            mainLegendClassName: "hidden",
            mainListClassName: "!my-0",
            checkboxGroups: {
              groups: [
                { list: ["Please have a real estate advisor contact me", "I am interested in buying or selling a property", "I am interested in joining the Coastal team"] }
              ]
            },
            checkboxGroupsWrapperClassNames: "!justify-start",
            checkboxListClassName: "!grid-flow-row !grid-rows-none !gap-1",
            inputSelect: {
              label: "My Desired Location is:",
              list: data.allCounty?.nodes?.map((c: any) => c.name)?.sort()
            },
            inputSelectLabelAsOption: false,
            selectWrapperClassNames: "!inline-grid !w-auto !border-b-0 pl-2",
            inputSelectClassNames: "!w-auto !bg-transparent",
            inputRange: {
              label: "My Price Range Is Between:"
            },
            inputTextClassNames: "!text-composite-text [&::placeholder]:!text-composite-text !border-composite-text",
            inputTextRangeClassNames: "!w-[90px] !text-composite-text [&::placeholder]:!text-composite-text !border-composite-text",
            submitButtonClassName: "!border-composite-text lg:!max-w-none uppercase",
            clickHandler: async (formInput: any) => {
              const recaptchaToken = await getRecaptchaToken("footer");
              let formValues = {
                firstName: formInput.firstName,
                lastName: formInput.lastName,
                email: formInput.email,
                phone: formInput.phone,
                haveAdvisorContact: formInput.interestedIn?.[0] && formInput.interestedIn.includes("Please have a real estate advisor contact me"),
                interestedInBuyingOrSelling: formInput.interestedIn?.[0] && formInput.interestedIn.includes("I am interested in buying or selling a property"),
                interestedInJoining: formInput.interestedIn?.[0] && formInput.interestedIn.includes("I am interested in joining the Coastal team"),
                lowPrice: parseFloat(formInput.priceRange?.min) || 0,
                highPrice: parseFloat(formInput.priceRange?.max) || 0,
                location: formInput.inputSelect?.value,
              }
              await axios({
                url: process.env.LEAD_CREATE_API_ENDPOINT,
                method: "POST",
                data: {
                  recaptchaToken,
                  formValues: formValues,
                  propertyInfo: {
                    createdFrom: `Footer - ${data.config.customFields.siteName}`,
                  },
                },
              })
            },
          }
        },
        footerLayout: FooterLayoutFormColumn,
        communityPageLayout: CommunityLayoutHeroGallery,
        communityLayoutProps: {
          showMap: false,
          showListingSearch: false,
          showMarketStats: true,
          showListingsCarousel: true,
          showContactUsForm: false,
          requestInfoFormProps: {
            title: "Inquire about property",
            mainLegendClassName: "uppercase",
            classNames: "strictTwoColumn !bg-[#9bafb3]",
            fieldsetClassName: "!py-1.5 !px-6 lg:!py-6 lg:!px-0 flex flex-col",
            mainListClassName: "!my-0",
            submitButtonClassName: "font-black !uppercase !text-sm !py-2",
            inputTextAreaClassName: "h-32"
          },
          heroCarouselProps: {
            className: "h-screen w-full",
          },
          centerBladeProps: {
            titleClassNames: "lg:text-[64px] leading-none mb-1",
            textWrapperClassNames: "bg-body-bg-inverted",
            buttonClassNames: "!p-0 !text-body-text !bg-transparent !font-black",
          },
          listingsCarouselProps: {
            linkPosition: ECarouselLinkPosition.bottom,
            link: "/places/property-search"
          },
          marketStatsProps: {
            title: "Market Report"
          }
        },
        headerProps: {
          className: "!absolute md:!fixed !from-black !bg-transparent !bg-gradient-to-b",
          addFlagOnScroll: true
        }
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}